import React from 'react';
import { Link } from 'react-router-dom';
import history from '../../history'
import "../css/kategori.css"

class SubKategoriList extends React.Component {
    routeChange(slug) {
        //masih ngga tau caranya render
        var path = "/kategori";
        if (slug !== "") {
            path = `${path}/${slug}`;
        }

        history.push(path);
    }
    doRenderSubKategori() {
        const { kategori } = this.props;
        return this.props.list.map(subkategori => {
            return (
                <div className="ui card" onClick={() => {this.routeChange(`${kategori.url_slug_return}/${subkategori.url_slug_return}`)}}>
                    <div className="content">
                    {subkategori.nama_pendek_return}
                    </div>
                    
                </div>
            );
            
        });
    }
    render () {
        return (
            <div className="grid">
                <div className="ui three link cards">
                    {this.doRenderSubKategori()}
                </div>
                
            </div>
        );
    }
}

export default SubKategoriList;