import React, {createRef} from 'react';
import { connect } from 'react-redux';
import { fetchPengumumanById } from '../actions/pengumuman';
import {DESKTOP_SITE} from '../actions/types';

//import {SearchBarWithArrow as SearchBar} from '../universal/SearchBar';
import Toolbar from '../universal/Toolbar'
import {Helmet} from 'react-helmet';
import * as moment from 'moment';
import Footer from '../universal/Footer';

import { titleCase } from '../actions/types';
import images from '../../api/images';
import '../css/pengumuman.css'

class Pengumuman extends React.Component {
    contextRef = createRef();

    constructor(props) {
        super(props);
    }
    async loadFromApi() {
        await this.props.fetchPengumumanById(this.props.match.params.id);
        //console.log("Info pengumuman", this.props.pengumumanInfo);
    }
    componentDidMount() {
        window.scrollTo(0,0);
        this.loadFromApi();
    }
    showPengumumanImage() {
        if (this.props.pengumumanInfo.isLoaded === true) {
            return (
                <img src={images(this.props.pengumumanInfo.data.url_image_return)} style={{
                    height: 'auto', width: '100%', marginLeft:'auto', marginRight: 'auto', display: 'block'
                    }} />
            );
        }
        else {
            return <div></div>
        }
    }
    showPengumumanInformation() {
        if (this.props.pengumumanInfo.isLoaded === true) {
            return (
                <div>
                    <h1 className="pengumuman-title">{titleCase(this.props.pengumumanInfo.data.judul_return)}</h1>
                    <div dangerouslySetInnerHTML={{__html: this.props.pengumumanInfo.data.deskripsi_return }}></div>
                </div>
            );
        }
        else {
            return <div></div>
        }
    }
    doRenderHeader() {
        if (this.props.pengumumanInfo.isLoaded === true) {
            var judulStr = titleCase(this.props.pengumumanInfo.data.judul_return) + " | Henata";
            return (
                <Helmet>
                    <title>{judulStr}</title>
                    <link rel="canonical" href={DESKTOP_SITE + this.props.location.pathname} />
                </Helmet>
            );
        }
        else {
            return (
                <Helmet>
                    <title></title>
                </Helmet>
            )
        }
    }
    renderLoading() {
        return (
            <div className="ui main container">
                <div className="ui grid">
                    <div className="eleven wide column">
                        <div className="ui placeholder" style={{ height: "100%", width: "100%" }}>
                            <div className="image" ></div>
                        </div>
                        <div className="card">
                            <div className="ui placeholder">
                                <div className="paragraph">
                                    <div class="line"></div>
                                    <div class="line"></div>
                                    <div class="line"></div>
                                    <div class="line"></div>
                                    <div class="line"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="five wide column">

                    </div>
                </div>
            </div>

        );
    }
    renderPengumuman() {
        return (
            <div style={{marginTop: "10%"}}>
                <div>
                    <div style={{width: "100%"}}>{this.showPengumumanImage()}</div>
                    <div className="container" style={{width: "100%"}}>
                        {this.showPengumumanInformation()}
                    </div>
                </div>
                {/* <div className="ui grid" id="pengumuman"> */}
                    
                    {/* <Ref innerRef={this.contextRef}>
                        <div className="five wide column">
                            <Sticky context={this.contextRef} offset={80}>
                                <Segment>
                                    <div style={{fontWeight: "bold"}}>Periode Promo</div>
                                    <div>{moment(this.props.pengumumanInfo.data.timestamp_terbit_return).format("DD-MM-YYYY") + " s/d " + 
                                    moment(this.props.pengumumanInfo.data.timestamp_selesai_return).format("DD-MM-YYYY")}</div>
                                    <DownloadApps message="Download aplikasi Henata untuk menggunakan promo ini." />
                                </Segment>
                            </Sticky>
                        </div>
                    </Ref> */}
                {/* </div> */}
            </div>
        );
    }
    doRenderPage() {
        if (this.props.pengumumanInfo.isLoaded === true) {
            return this.renderPengumuman();
        }
        else {
            return this.renderLoading();
        }
    }
    render() {
        return (
            <div>
                {this.doRenderHeader()};
                <Toolbar />
                <div>
                {this.doRenderPage()}
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { pengumumanInfo: state.pengumumanInfo }
}
const connectPengumuman = connect(mapStateToProps, { fetchPengumumanById })(Pengumuman);
export { connectPengumuman };