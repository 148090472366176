const INITIAL_STATE_LIST_PRODUCT = {
    isLoaded: false,
    total: 0,
    from: 0,
    to: 0,
    products: []
}
const INITIAL_STATE_SUGGEST_KEYWORD = {
    isLoaded: false,
    suggest: []
}

export const searchProductByKategori = (state = INITIAL_STATE_LIST_PRODUCT, action) => {
    switch(action.type) {
        case 'FETCH_SEARCH_PRODUCT_BY_KATEGORI_RESET':
            return INITIAL_STATE_LIST_PRODUCT;
        case 'FETCH_SEARCH_PRODUCT_BY_KATEGORI':
            return {...state, 
                isLoaded: true, 
                id_kategori: action.payload.id_kategori,
                id_sub_kategori: action.payload.id_sub_kategori,
                total: action.payload.total, 
                from: action.payload.from, 
                to: action.payload.to, 
                products: action.payload.data};
        default:
            return state;
    }
}
export const searchProductByKeyword = (state = INITIAL_STATE_LIST_PRODUCT, action) => {
    switch(action.type) {
        case 'FETCH_SEARCH_PRODUCT_BY_KEYWORD_RESET':
            return INITIAL_STATE_LIST_PRODUCT;
        case 'FETCH_SEARCH_PRODUCT_BY_KEYWORD':
            return {...state, isLoaded: true, keyword: action.payload.keyword, total:action.payload.total, from: action.payload.from, to: action.payload.to, products: action.payload.data};
        default:
            return state;
    }
}
export const searchKeywordSuggestion = (state = INITIAL_STATE_SUGGEST_KEYWORD, action) => {
    switch(action.type) {
        case 'FETCH_SEARCH_SUGGEST_AUTOCOMPLETE_RESET':
            return INITIAL_STATE_SUGGEST_KEYWORD;
        case 'FETCH_SEARCH_SUGGEST_AUTOCOMPLETE':
            return {...state, isLoaded: true, suggest: action.payload}
        default:
            return state;
    }
}