import mapis from '../../api/mapis';
import _ from 'lodash';
import { async } from 'q';

export const resetKategoriAndSubKategoriUrlSlug = () => async dispatch => {
    dispatch({
        type: 'FETCH_KATEGORI_URL_SLUG_RESET'
    });
    dispatch({
        type:'FETCH_SUB_KATEGORI_URL_SLUG_RESET'
    })
} 
export const fetchKategoriAndSubKategori = () => async (dispatch, getState) => {
    dispatch({
        type: 'FETCH_KATEGORI_ALL_RESET'
    })
    dispatch({
        type: 'FETCH_SUB_KATEGORI_RESET'
    })
    await dispatch(fetchKategoriAll());

    const listIdKategori = _.uniq(_.map(getState().listKategoriAll.kategori, 'id_return'));
    listIdKategori.forEach( async id => {
        await dispatch(fetchSubKategori(id));
    });
}
export const fetchKategoriAndSubKategoriUrlSlug = (kat_url_slug, subkat_url_slug) => async (dispatch, getState) => {
    await dispatch(fetchKategoriFromUrlSlug(kat_url_slug));
    if (getState().kategoriUrlSlug.isFound === true) {
        await dispatch(fetchSubKategoriFromUrlSlug(getState().kategoriUrlSlug.data.id_return, subkat_url_slug));
    }
}


//single function
export const fetchKategoriAll = () => async dispatch => {
    const response = await mapis.get('/mart/kategori/all');
    if (response.data.status === "success") {
        dispatch({
            type: 'FETCH_KATEGORI_ALL',
            payload: response.data.data
        })
    }
}

export const fetchSubKategori = (id_kategori) => async dispatch => {
    const response = await mapis.get(`/mart/kategori/sub/${id_kategori}`);
    if (response.data.status==="success") {
        var object = {
            "id_kategori": id_kategori.toString(),
            "sub_kategori": response.data.data
        }
        dispatch({
            type: 'FETCH_SUB_KATEGORI',
            payload: object
        })
    }
}

export const fetchKategoriFromUrlSlug = (url_slug) => async dispatch => {
    dispatch({
        type: 'FETCH_KATEGORI_URL_SLUG_RESET'
    });
    dispatch({
        type: 'FETCH_SUB_KATEGORI_URL_SLUG_RESET'
    })
    const response = await mapis.get(`/mart/kategori/slug/${url_slug}`);
    console.log(response);
    if (response.data.status==="success") {
        console.log(response.data.data);
        if (response.data.data.length >= 1) {
            dispatch({
                type: 'FETCH_KATEGORI_URL_SLUG',
                url_slug: url_slug,
                payload: response.data.data[0]
            })
        }
        else {
            dispatch({
                type: 'FETCH_KATEGORI_URL_SLUG_NOTFOUND',
                url_slug: url_slug
            })
        }
    }
}

export const fetchSubKategoriFromUrlSlug = (id_kategori, url_slug) => async dispatch => {
    // dispatch({
    //     type: 'FETCH_SUB_KATEGORI_URL_SLUG_RESET'
    // });
    const response = await mapis.get(`/mart/kategori/slug/sub/${url_slug}`);
    if (response.data.status==="success") {
        if (response.data.data.length >= 1) {
            if (response.data.data[0].id_kategori_return === id_kategori) {
                dispatch({
                    type: 'FETCH_SUB_KATEGORI_URL_SLUG',
                    url_slug: url_slug,
                    payload: response.data.data[0]
                })
            }
            else {
                dispatch({
                    type: 'FETCH_SUB_KATEGORI_URL_SLUG_NOTFOUND',
                    url_slug: url_slug
                })
            }
        }
        else {
            dispatch({
                type: 'FETCH_SUB_KATEGORI_URL_SLUG_NOTFOUND',
                url_slug: url_slug
            })
        }
    }
}