import React from 'react';
import history from '../../history';

class Toolbar extends React.Component {
    onBackPressed = event => {
        event.preventDefault();
        history.goBack();
    }
    render() {
        return (
            <div className="ui fixed menu">
                {/* <div className="main container" > */}
                    <div className="header borderless item">
                        <i className="material-icons" onClick ={this.onBackPressed}>arrow_back</i>
                    </div>
                    <div className="item borderless">
                        <h3>{this.props.title}</h3>
                    </div>
                    
                {/* </div> */}
                
            </div>
        );
    }
}

export default Toolbar;