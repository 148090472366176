import React from 'react';
import images from '../../api/images';
import history from '../../history';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import {titleCase} from '../actions/types'

import '../css/products.css'

class SearchItem extends React.Component {
    routeChange(slug) {
        //masih ngga tau caranya render
        let path = `/product/${slug}`;
        history.push(path);
    }
    showProductInfo() {
        if (this.props.diskon && this.props.diskon.is_diskon_exist === true) {
            var priceText = <div></div>;
            var diskonValue = 0, diskonPersen = 0, hargaSetelahDiskon = 0;
            var objectDiskon = this.props.diskon.diskon;
            if (objectDiskon.min_pembelian_return === -1 && objectDiskon.max_pembelian_return === -1) {
                diskonValue = Number(objectDiskon.diskon_value_return);
                diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.item.harga_barang_return))/100;

                hargaSetelahDiskon = Number(this.props.item.harga_barang_return) - diskonValue - diskonPersen;
                priceText = (
                    <div>
                        <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp. '}
                        renderText={value => <div className="src_p_dsc_harga_barang_normal_text"><strike>{value}</strike></div>} />
                        <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp. '}
                        renderText={value => <div className="src_p_dsc_harga_barang_after_diskon_text">{value}</div>} />
                    </div>
                );
            }
            else if (objectDiskon.min_pembelian_return === -1 && objectDiskon.max_pembelian_return > 0) {
                diskonValue = Number(objectDiskon.diskon_value_return);
                diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.item.harga_barang_return))/100;

                hargaSetelahDiskon = Number(this.props.item.harga_barang_return) - diskonValue - diskonPersen;
                priceText = (
                    <div>
                        <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp. '}
                        renderText={value => <div className="src_p_harga_barang_text">{value}</div>}
                        />
                        <div className="src_p_diskon_condition_text">
                            Beli maks. {objectDiskon.max_pembelian_return} item,
                            <span style={{display: "inline-block"}}>
                                <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'Rp. '}
                                renderText={value => <div>{value}</div>} />
                            </span>
                            / item.
                        </div>
                    </div>
                ); 
            }
            else if (objectDiskon.min_pembelian_return > 0 && objectDiskon.max_pembelian_return === -1) {
                diskonValue = Number(objectDiskon.diskon_value_return);
                diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.item.harga_barang_return))/100;

                hargaSetelahDiskon = Number(this.props.item.harga_barang_return) - diskonValue - diskonPersen;
                
                if (objectDiskon.min_pembelian_return > 1) {
                    priceText = (
                        <div>
                            <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp. '}
                            renderText={value => <div className="src_p_harga_barang_text">{value}</div>}
                            />
                            <div className="src_p_diskon_condition_text">
                                Beli min. {objectDiskon.min_pembelian_return} item,
                                <span style={{display: "inline-block"}}>
                                    <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                                    decimalSeparator={','} prefix={'Rp. '}
                                    renderText={value => <div>{value}</div>} />
                                </span>
                                / item.
                            </div>
                        </div>
                    );
                }
                else {
                    priceText = (
                        <div>
                            <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp. '}
                            renderText={value => <div className="src_p_dsc_harga_barang_normal_text"><strike>{value}</strike></div>} />
                            <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp. '}
                            renderText={value => <div className="src_p_dsc_harga_barang_after_diskon_text">{value}</div>} />
                        </div>
                    );
                }
            }
            else if (objectDiskon.min_pembelian_return > 0 && objectDiskon.max_pembelian_return > 0) {
                diskonValue = Number(objectDiskon.diskon_value_return);
                diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.item.harga_barang_return))/100;

                hargaSetelahDiskon = Number(this.props.item.harga_barang_return) - diskonValue - diskonPersen;
                priceText = (
                    <div>
                        <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp. '}
                        renderText={value => <div className="src_p_harga_barang_text">{value}</div>}
                        />
                        <div className="src_p_diskon_condition_text">
                            Beli {objectDiskon.min_pembelian_return} - {objectDiskon.max_pembelian_return} item,
                            <span style={{display: "inline-block"}}>
                                <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'Rp. '}
                                renderText={value => <div>{value}</div>} />
                            </span>
                            / item.
                        </div>
                    </div>
                );

            }
            return priceText;
        }
        else {
            return (
                <div>
                    <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                    decimalSeparator={','} prefix={'Rp. '}
                    renderText={value => <div className="src_p_harga_barang_text">{value}</div>} />
                 
                </div>
                       
            );
        }
    }
    render() {
        //console.log(this.props.item.url_slug_return)
        return (
            <div className="card" onClick={() => this.routeChange(this.props.item.url_slug_return) }>
                <div className="image mini" style={{background: "white"}}>
                    <img alt={this.props.item.nama_barang_return} key={this.props.item.id_barang_return} src={images(this.props.item.main_img_url_return)} style={{
                        height: '150px', width: 'auto', maxWidth: '100%', objectFit: 'fill',
                        marginLeft:'auto', marginRight: 'auto', display: 'block'
                        }} />
                </div>    
                <div className="content">
                    <div className="src_p_nama_barang_text">
                    {titleCase(this.props.item.nama_barang_return)}
                    </div>
                    <div style={{marginTop: '10px'}}>
                        {this.showProductInfo()}
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    //console.log("OwnProps : " + ownProps.item.id_barang_inventaris_return);
    return { 
        diskon: state.diskonByInventarisBatch.find(dsc=> {
            
            if (dsc.id_barang_inventaris == ownProps.item.id_barang_inventaris_return) {
                //console.log("OwnProps : " + ownProps.item.id_barang_inventaris_return + " Diskon : " + dsc.id_barang_inventaris)
                return dsc;
            }
            else return null;
        })
    }
}

export default connect(mapStateToProps, null)(SearchItem);