import React from 'react';
import { fetchPrivacyPolicy, fetchTerms } from '../actions/statics';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { DESKTOP_SITE } from '../actions/types'
//import {SearchBarWithArrow as SearchBar} from '../universal/SearchBar'
import Toolbar from '../universal/Toolbar';
import Footer from '../universal/Footer';

class TermsCondition extends React.Component {
    loadFromApi() {
        this.props.fetchTerms();
    }
    componentDidMount(){
        window.scrollTo(0,0);
        this.loadFromApi();
    }
    doRenderHeader() {
        return (
            <Helmet>
                <title>Syarat dan Ketentuan | Henata</title>
                <link rel="canonical" href={DESKTOP_SITE + this.props.location.pathname} />
            </Helmet>
        );
    }
    doRenderPage() {
        var rendered;
        if (this.props.termsCondition.isLoaded === true) {
            //console.log(this.props.privacyPolicy.data)
            rendered = (
                <div className="ui main container">
                    <h1>Syarat dan Ketentuan</h1>
                    <div dangerouslySetInnerHTML={{__html: this.props.termsCondition.data.statics_value_return }}></div>
                </div>
            );
        }
        else {
            rendered = <div></div>
        }
        return rendered;
    }
    render() {
        return (
            <div>
                {this.doRenderHeader()}
                <Toolbar />
                <div>
                {this.doRenderPage()}
                </div>
            </div>
        );
    }
}

class PrivacyPolicy extends React.Component {
    loadFromApi() {
        this.props.fetchPrivacyPolicy();
    }
    componentDidMount(){
        window.scrollTo(0,0);
        this.loadFromApi();
    }
    doRenderHeader() {
        return (
            <Helmet>
                <title>Kebijakan Privasi | Henata</title>
                <link rel="canonical" href={DESKTOP_SITE + this.props.location.pathname} />
            </Helmet>
        );
    }
    doRenderPage() {
        var rendered;
        if (this.props.privacyPolicy.isLoaded === true) {
            //console.log(this.props.privacyPolicy.data)
            rendered = (
                <div className="ui main container">
                    <h1>Kebijakan Privasi</h1>
                    <div dangerouslySetInnerHTML={{__html: this.props.privacyPolicy.data.statics_value_return }}></div>
                </div>
            );
        }
        else {
            rendered = <div></div>
        }
        return rendered;
    }
    render() {
        return (
            <div>
                {this.doRenderHeader()}
                <Toolbar />
                <div>
                {this.doRenderPage()}
                </div>
                <Footer />
            </div>
        );
    }
}

const privacyMapStateToProps = (state) => {
    return {privacyPolicy: state.privacyPolicy}
}
const termsMapStateToProps = (state) => {
    return {termsCondition: state.termsCondition}
}
const connectPrivacyPolicy = connect(privacyMapStateToProps, { fetchPrivacyPolicy })(PrivacyPolicy);
const connectTermsCondition = connect(termsMapStateToProps, {fetchTerms})(TermsCondition);

export {connectPrivacyPolicy, connectTermsCondition}
