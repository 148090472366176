const INITIAL_STATE_INFO = {
    isLoaded: false,
    data: null
};

export const privacyPolicy = (state = INITIAL_STATE_INFO, action) => {
    switch(action.type) {
        case 'FETCH_PRIVACY_POLICY':
            return {...state, isLoaded: true, data: action.payload};
        case 'FETCH_PRIVACY_POLICY_RESET':
            return INITIAL_STATE_INFO;
        default:
            return state;
    }
}
export const termsCondition = (state = INITIAL_STATE_INFO, action) => {
    switch(action.type) {
        case 'FETCH_TERMS':
            return {...state, isLoaded: true, data: action.payload};
        case 'FETCH_TERMS_RESET':
            return INITIAL_STATE_INFO;
        default:
            return state;
    }
}
export const seoHome = (state = INITIAL_STATE_INFO, action) => {
    switch(action.type) {
        case 'FETCH_SEO_HOME':
            return {...state, isLoaded: true, data: action.payload};
        case 'FETCH_SEO_HOME_RESET':
            return INITIAL_STATE_INFO;
        default:
            return state;
    }
}