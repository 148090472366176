import mapis from '../../api/mapis';

export const fetchPengumumanById = (id) => async dispatch => {
    dispatch({
        type: 'FETCH_PENGUMUMAN_BY_ID_RESET'
    })
    var url = `/mart/pengumuman/read/id/${id}`;
    const response = await mapis.get(url);
    if (response.data.status==="success") {
        dispatch({
            type: 'FETCH_PENGUMUMAN_BY_ID',
            payload: response.data.data
        });
    }
}