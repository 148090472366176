import React from 'react';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    doRenderFooter() {
        if (this.props.link_only === "true") {
            return (
                <footer>
                    <div className="ui vertical footer segment" style={{marginTop: "30px", paddingTop:"20px", paddingBottom:"20px"}}>
                        <div className="ui center aligned container">
                            <div className="ui horizontal small divided link list">
                                <p className="item">2019 Henata</p>
                                <Link className="item" to="/privacy">Kebijakan Privasi</Link>
                                <Link className="item" to="/terms">Syarat dan Ketentuan</Link>
                            </div>
                        </div>
                    </div>
                </footer>
                
            );
        }
        else {
            return (
                <footer>
                    <div className="ui vertical footer segment" style={{marginTop: "30px", paddingTop:"20px", paddingBottom:"20px"}}>
                        <div className="ui center aligned container">
                            <p>Download aplikasi henata</p>
    
                            <div>
                            <a className="ui centered image" href="https://play.google.com/store/apps/details?id=com.henata.app" target="_blank">
                                <img  src="/assets/img/google_play.png" className="playstore-img" /></a>
                            </div>
                            
                            <div className="ui horizontal small divided link list">
                                <p className="item">2019 Henata</p>
                                <Link className="item" to="/privacy">Kebijakan Privasi</Link>
                                <Link className="item" to="/terms">Syarat dan Ketentuan</Link>
                            </div>
                        </div>
                    </div>
                </footer>
                
            );
        }
    }
    render() {
        return (
            <div>
                {this.doRenderFooter()}
            </div>
        );
    }
}

export default Footer;