import React from 'react';
import { Link } from 'react-router-dom';
import history from '../../history';

import '../css/home.css';
import '../css/Home/featured-kategori-grids.css'

class FeaturedKategoriList extends React.Component {
    routeChange(slug) {
        //masih ngga tau caranya render
        var path = "/kategori";
        if (slug !== "") {
            path = `${path}/${slug}`;
        }

        history.push(path);
    }
    componentDidMount() {
        this.doRenderStyle();
    }
    doRenderStyle() {
        const lists = document.querySelectorAll('.hs-fk'); 
        //console.log("Lists featured", lists);
        lists.forEach(el => {
        //const listItems = el.querySelectorAll('li');
        const n = el.children.length;
        el.style.setProperty('--total-fk', n);
        });
    }
    render() {
        return (
            <div>
                <h3 className="heading">Top Picks</h3>
                <div className="nata-hs-fk-container">
                <ul className="nata-hs-ul-fk hs-fk full">
                    <li className="nata-item-fk">
                        <div className="ui link card eq-card" onClick={() => {this.routeChange("food/makanan-pokok")}}>
                            {/* <div className="img-parent"> */}
                            <img className="eq-image"  src="/assets/img/makanan-pokok-300.png" />
                            <div className="featured-kat-text">Makanan Pokok</div>
                            {/* </div> */}
                        </div>
                    </li>
                    <li className="nata-item-fk">
                        <div className="ui link card eq-card" onClick={() => {this.routeChange("food/makanan-ringan")}}>
                            {/* <div className="img-parent"> */}
                                <img className="eq-image" src="/assets/img/snack-300.png"/>
                                <div className="featured-kat-text">Snack</div>
                            {/* </div> */}
                        </div>
                    </li>
                    <li className="nata-item-fk">
                        <div className="ui link card eq-card" onClick={() => {this.routeChange("non-food/perawatan-pribadi")}}>
                            {/* <div className="img-parent"> */}
                            <img className="eq-image" src="/assets/img/personal-care-300.png"/>
                                <div className="featured-kat-text">Personal Care</div>
                            {/* </div> */}
                        </div>
                    </li>
                    <li className="nata-item-fk">
                        <div className="ui link card eq-card" style={{width:"150px", height: "auto"}} onClick={() => {this.routeChange("food/minuman")}}>
                            {/* <div className="img-parent"> */}
                            <img className="eq-image" src="/assets/img/minuman-300.png"/>
                                <div className="featured-kat-text">Minuman</div>
                            {/* </div> */}
                        </div>
                    </li>
                    <li className="nata-item-fk">
                        <div className="ui link card eq-card" style={{width:"150px", height: "auto"}} onClick={() => {this.routeChange("food/makanan-instan")}}>
                            {/* <div className="img-parent"> */}
                            <img className="eq-image" src="/assets/img/makanan-instan-300.png"/>
                                <div className="featured-kat-text">Makanan Instan</div>
                            {/* </div> */}
                        </div>
                    </li>
                    <li className="nata-item-fk">
                        <div className="ui link card fluid eq-card" onClick={() => {this.routeChange("")}}>
                             <div className="featured-kat-semua-kategori">Lihat semua kategori</div>
                         </div>
                    </li>
                </ul>
            </div>

            </div>
        );
    }
    //old render
                // <div className="ui three column grid">
            //     <div className="column">
            //         <div className="ui link card eq-card" onClick={() => {this.routeChange("food/makanan-pokok")}}>
            //             {/* <div className="img-parent"> */}
            //             <img className="eq-image" src="/assets/img/makanan-pokok-300.png" />
            //             <div className="featured-kat-text">Makanan Pokok</div>
            //             {/* </div> */}
            //         </div>
            //     </div>
            //     <div className="column">
            //         <div className="ui link card eq-card" onClick={() => {this.routeChange("food/makanan-ringan")}}>
            //             {/* <div className="img-parent"> */}
            //                 <img className="eq-image" src="/assets/img/snack-300.png"/>
            //                 <div className="featured-kat-text">Snack</div>
            //             {/* </div> */}
            //         </div>
            //     </div>
            //     <div className="column">
            //         <div className="ui link card eq-card" onClick={() => {this.routeChange("non-food/perawatan-pribadi")}}>
            //             {/* <div className="img-parent"> */}
            //                 <img className="eq-image" src="/assets/img/personal-care-300.png"/>
            //                 <div className="featured-kat-text">Personal Care</div>
            //             {/* </div> */}
            //         </div>
            //     </div>
            //     <div className="column">
            //         <div className="ui link card fluid eq-card" onClick={() => {this.routeChange("food/minuman")}}>
            //             <img className="eq-image" src="/assets/img/minuman-300.png"/>
            //             <div className="featured-kat-text">Minuman</div>
            //         </div>
            //     </div>
            //     <div className="column">
            //         <div className="ui link card fluid eq-card" onClick={() => {this.routeChange("food/makanan-instan")}}>
            //             <img className="eq-image" src="/assets/img/makanan-instan-300.png"/>
            //             <div className="featured-kat-text">Makanan Instan</div>
            //         </div>
            //     </div>
            //     <div className="column">
            //         <div className="ui link card fluid eq-card" onClick={() => {this.routeChange("")}}>
            //             <div className="featured-kat-semua-kategori">Lihat semua kategori</div>
            //         </div>
            //     </div>
            // </div>
}

export default FeaturedKategoriList;