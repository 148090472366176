const INITIAL_STATE_INFO = {
    isLoaded: false,
    product: null
};

export const productInfo = (state = INITIAL_STATE_INFO, action) => {
    switch(action.type) {
        case 'FETCH_PRODUCT_INFO':
            return {...state, isLoaded: true, product: action.payload};
        case 'FETCH_PRODUCT_INFO_RESET':
            return INITIAL_STATE_INFO;
        default:
            return state;
    }
}