const INITIAL_STATE_DISKON = {
    isDiskonExist : null,
    diskon: null
}
const INITIAL_STATE_DISKON_BATCH = [];
const INITIAL_STATE_DISKON_ALL = {
    isLoaded : false,
    diskon: []
}

export const diskonByInventaris = (state = INITIAL_STATE_DISKON, action) => {
    switch(action.type) {
        case 'FETCH_RESET_DISKON_BY_INVENTARIS': 
            return INITIAL_STATE_DISKON;
        case 'FETCH_DISKON_INVENTARIS_EXIST':
            return {...state, isDiskonExist: true, diskon: action.payload};
        case 'FETCH_DISKON_INVENTARIS_NOTEXIST':
            return {...state, isDiskonExist: false, diskon: null};
        default:
            return state;
    }
}
export const diskonByInventarisBatch = (state = INITIAL_STATE_DISKON_BATCH, action) => {
    switch(action.type) {
        case 'FETCH_RESET_DISKON_BY_INVENTARIS_BATCH':
            return INITIAL_STATE_DISKON_BATCH;
        case 'FETCH_DISKON_INVENTARIS_BATCH_EXIST':
            return [...state, action.payload];
        case 'FETCH_DISKON_INVENTARIS_BATCH_NOTEXIST' :
            return [...state, action.payload];
        default:
            return state;
    }
}
export const listDiskonAll = (state = INITIAL_STATE_DISKON_ALL, action) => {
    switch(action.type) {
        case 'FETCH_RESET_DISKON_ALL':
            return INITIAL_STATE_DISKON_ALL;
        case 'FETCH_DISKON_ALL':
            return {...state, isLoaded: true, diskon: action.payload};
        default:
            return state;
    }
}