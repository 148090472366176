import mapis from '../../api/mapis'
import { fetchDiskonAll } from './diskon';
import { async } from 'q';

//bikin fungsi, yang return fungsi dispatch
export const fetchLinimasaAndDiskonAll = () => async (dispatch, getState) => {
    await dispatch(fetchLinimasa());
    
    if (getState().listLinimasa) {
        console.log(getState().listLinimasa);
        await dispatch(fetchDiskonAll());
        console.log(getState().listDiskonAll);
    }
}

export const fetchLinimasa = () => async dispatch => {
    dispatch({
        type: 'FETCH_RESET_LINIMASA'
    })
    const response = await mapis.get('/mart/linimasa/all', {
        params: {
            jenis: 1
        }
    });

    if (response.data.status==="success") {
        dispatch({
            type: 'FETCH_LINIMASA',
            payload: response.data.data
        })
    }
}

export const fetchPengumuman = () => async dispatch => {
    const response = await mapis.get('/mart/pengumuman/read/today');

    if (response.data.status==="success") {
        dispatch({
            type: 'FETCH_PENGUMUMAN',
            payload: response.data.data
        })
    }
}