import React from 'react';
import { Link } from 'react-router-dom';

import '../css/product-detail.css';

class DownloadApps extends React.Component {
    render() {
        return (
        <div>
            <p className="download-apps-text">
               {this.props.message}
            </p>
            <a href="https://play.google.com/store/apps/details?id=com.henata.app" target="_blank"
            ><img src="/assets/img/google_play.png" className="playstore-img" /></a>
        </div>
        );
    }
}

export default DownloadApps;