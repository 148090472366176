export const JENIS_REGULER = 1;
export const DEFAULT_SIZE_PAGING = 10;
export const DESKTOP_SITE = "https://www.henata.com";
export const MOBILE_SITE = "https://m.henata.com"

export const titleCase = (str) => {
    str = str.toLowerCase().split(' ');

   let final = [ ];

    for(let  word of str){
      final.push(word.charAt(0).toUpperCase()+ word.slice(1));
    }

    return final.join(' ')
}