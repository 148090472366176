import { combineReducers } from 'redux';
import { listPengumuman, pengumumanInfo } from './pengumuman/pengumumanReducers';
import { listLinimasa, listProductByLinimasaId, linimasaInfo } from './linimasa/linimasaReducers';
import { listDiskonAll, diskonByInventaris, diskonByInventarisBatch } from './diskon/diskonReducers';
import { listKategoriAll, listSubKategoriByIdKategori, kategoriByUrlSlug, subkategoriByUrlSlug } from './kategori/kategoriReducers';

import { productInfo } from './product/productReducers';

import { privacyPolicy, termsCondition, seoHome } from './statics/staticReducers';

//search
import { searchProductByKategori, searchProductByKeyword, searchKeywordSuggestion } from './search/searchReducers';

export default combineReducers({
    listPengumuman : listPengumuman,
    listLinimasa : listLinimasa,
    listDiskonAll : listDiskonAll,
    listKategoriAll : listKategoriAll,
    listSubKategori : listSubKategoriByIdKategori,
    productInfo : productInfo,
    linimasaInfo : linimasaInfo,
    listProductByLinimasaId : listProductByLinimasaId,
    pengumumanInfo: pengumumanInfo,
    diskonByInventaris: diskonByInventaris,
    diskonByInventarisBatch: diskonByInventarisBatch,
    kategoriUrlSlug: kategoriByUrlSlug,
    subkategoriUrlSlug: subkategoriByUrlSlug,
    searchProductByKategori: searchProductByKategori,
    searchProductByKeyword: searchProductByKeyword,
    searchKeywordSuggestion: searchKeywordSuggestion,
    privacyPolicy: privacyPolicy,
    termsCondition: termsCondition,
    seoHome: seoHome
})
