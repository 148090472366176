import React from 'react';
import images from '../../api/images';
import history from '../../history'

class PengumumanItem extends React.Component {
    state = { itemFeatured : null };
    constructor(props) {
        super(props);
    }
    routeChange(id) {
        //masih ngga tau caranya render
        let path = `/pengumuman/${id}`;
        history.push(path);
    }
    render() {
        //console.log(images(this.props.item.url_image_return));
        // if (this.props.active === "true") {
        //     return (
        //         <div className="carousel-item active" style={{cursor: "pointer"}} onClick={() => {this.routeChange(this.props.item.id_return)}}>
        //             <img className="d-block w-100 image" src={images(this.props.item.url_image_return)} />
        //         </div>
        //     );
        // }
        // else {
        //     return (
            
        //         <div className="carousel-item" style={{cursor: "pointer"}} onClick={() => {this.routeChange(this.props.item.id_return)}}>
        //             <img className="d-block w-100 image" src={images(this.props.item.url_image_return)} />
        //         </div>
        //     );
        // }
        if (this.props.active === "true") {
            return (
                <li className="nata-item" style={{cursor: "pointer"}} onClick={() => {this.routeChange(this.props.item.id_return)}}>
                    <img alt={this.props.item.judul_return} style={{width: "100%", height: "auto", borderRadius: "8px"}} src={images(this.props.item.url_image_return)} />
                </li>
            );
        }
        else {
            return (
            
                <li className="nata-item" style={{cursor: "pointer"}} onClick={() => {this.routeChange(this.props.item.id_return)}}>
                    <img alt={this.props.item.judul_return} style={{width: "100%", height: "auto", borderRadius: "8px"}} src={images(this.props.item.url_image_return)} />
                </li>
            );
        }
    }
}

export default PengumumanItem;