import React from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Link } from 'react-router-dom';
import { fetchSearchKeywordSuggestion, resetSearchKeywordSuggestion } from '../actions/search';
import { connect } from 'react-redux';

import history from '../../history';
import "../css/search.css"

class SearchBar extends React.Component {
    state = { query: '', isInputClicked: false, heightSearchPanel: 0 };
    targetElement = React.createRef();
    searchInput = React.createRef();


    onSearchInputChange = event => {
        this.loadKeywordSuggestion(event.target.value);
        this.setState({query: event.target.value});
    }
    onFormSubmit = event => {
        event.preventDefault();

        //this.props.onSubmit(this.state.query);
        if (this.props.fromSearch==="true") {
            this.setState({isInputClicked: false});
            enableBodyScroll(this.targetElement);

            history.push({
                pathname: "/search",
                search: "?q="+this.state.query+"&src=bar&by=products"
            });
        }
        else {
            history.push({
                pathname: "/search",
                search: "?q="+this.state.query+"&src=bar&by=products"
            });
        }
    }
    onClickSuggestion = (keyword) => {
        if (this.props.fromSearch==="true") {
            this.setState({isInputClicked: false});
            enableBodyScroll(this.targetElement);

            history.push({
                pathname: "/search",
                search: "?q="+keyword+"&src=bar&by=products"
            });
        }
        else {
            history.push({
                pathname: "/search",
                search: "?q="+keyword+"&src=bar&by=products"
            });
        }
    }
    onClickInput = event => {
        event.preventDefault();
        window.scrollTo(0,0);
        this.props.resetSearchKeywordSuggestion();
        if (this.props.fromSearch === "true") {
            this.setState({isInputClicked: true})
        }
        else {
            this.setState({isInputClicked: true, query: ''});
        }
        disableBodyScroll(this.targetElement);
    };
    onBackPressed = event => {
        event.preventDefault();
        this.setState({isInputClicked: false});
        enableBodyScroll(this.targetElement);
    }
    onBackPressedHistory = event => {
        event.preventDefault();
        history.goBack();
    }

    loadKeywordSuggestion(query) {
        //console.log("Loading query", query);
        this.props.fetchSearchKeywordSuggestion(query);
    }

    doRenderSuggestion() {
        const { suggestion } = this.props;
        //console.log(suggestion.suggest);
        if (suggestion.isLoaded === true) {
            if (suggestion.suggest.completion && suggestion.suggest.completion.length >= 1) {
                const renderedList = suggestion.suggest.completion.map(item => {
                    return (
                        <div className="item" onClick={()=> {this.onClickSuggestion(item.autocomplete)}}>
                            <div className="content">
                                <div className="suggestion-items">{item.autocomplete}</div>
                                di {item.source.sub_kategori}
                            </div>
                        </div>
                    );
                });
                return (
                    <div>
                        <div className="ui middle aligned selection list">
                            {renderedList}
                        </div>
                    </div>
                );
            }
            else if (suggestion.suggest.phrase.length >= 0) {
                const renderedList = suggestion.suggest.phrase.map(item => {
                    return (
                        <div className="item" onClick={()=> {this.onClickSuggestion(item.autocomplete)}}>
                            <div className="content">
                                <div className="suggestion-items">{item.autocomplete}</div>
                            </div>
                        </div>
                    );
                })
                return (
                    <div>
                        <div className="ui middle aligned selection list">
                            {renderedList}
                        </div>
                    </div>
                );
            }
            else {
                return <div></div>
            }
        } 
        
    }
    doRenderSearchMenu() {
        //console.log("Arrow", this.props.arrow)
        if (this.state.isInputClicked === true) {
            return (
                <div className="ui menu" id="stick_search_panel">
                    <div className="header borderless item">
                        <i className="material-icons" onClick ={this.onBackPressed}>arrow_back</i>
                    </div>
                    <div style={{width: '100%', margin: '8px'}}>
                        <form className="ui form" onSubmit={this.onFormSubmit}>
                            <div className="ui fluid left icon input">
                            <input ref={this.searchInput} className="search-input-active" type="search" placeholder="Cari di henata" aria-label="Search" value={this.state.query} onChange={this.onSearchInputChange} />
                            <i className="search icon"></i>
                            </div>
                        </form>
                    </div>
                </div>
            );
        }
        else {
            if (this.props.arrow==="true") {
                return (
                    <div className="ui fixed menu">
                    {/* <div className="ui container" > */}
                        <div className="header borderless item">
                            <i className="material-icons" onClick ={this.onBackPressedHistory}>arrow_back</i>
                        </div>
                        <div style={{width: '100%', margin: '8px'}}>
                            <form className="ui form" onClick ={this.onClickInput}>
                                <div className="ui fluid left icon input">
                                <input className="search-input" type="search" placeholder="Cari di henata" value={this.state.query} aria-label="Search" />
                                <i className="search icon"></i>
                                </div>
                            </form>
                        </div>
                        
                    {/* </div> */}
                    </div>
                );
                
            }
            else {
                return (
                    <div className="ui fixed menu">
                        <div style={{width: '100%', margin: '8px'}}>
                            <form className="ui form" onClick ={this.onClickInput}>
                                <div className="ui fluid left icon input">
                                <input className="search-input" type="search" placeholder="Cari di henata" aria-label="Search" />
                                <i className="search icon"></i>
                                </div>
                            </form>
                        </div>
                    </div>
                    
                );
            }
            
        }
    }
    doRenderSearchPanel() {
        if (this.state.isInputClicked === true) {
            return (
                <div className="search_div">
                    <div className="scrollable-content">
                        {this.doRenderSuggestion()}
                    </div>
                </div>
            );
        }
        else {
            return (
                <div></div>
            );
        }
    }
    componentDidMount() {
        //console.log("SearchBar Did mount");
        if (this.props.defaultQuery) {
            this.setState({query: this.props.defaultQuery})
        }
    }
    componentDidUpdate() {
        //  console.log("height navbar: ",this.divElement.clientHeight);
        //  console.log("height window:", window.innerHeight);
        //console.log("SearchBar Did Update, default Query:", this.props.defaultQuery)
        if (this.state.isInputClicked === false) {
            if (this.state.query !== this.props.defaultQuery) {
                this.setState({query: this.props.defaultQuery})
            }
            //console.log("isInputClick false");
        }

        if (this.searchInput.current !== null) {
            this.searchInput.current.focus();
        }
        
    }
    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }
    render() {
        return (
            <div>
                <div ref={(divElement) => this.divElement = divElement}>
                    {this.doRenderSearchMenu()}
                </div>
                <div ref={this.targetElement}>
                    {this.doRenderSearchPanel()}
                </div>
            </div>
            
        );
    }
}

class SearchBarWithArrow extends React.Component {
    state = { query: '' };

    onFormSubmit = event => {
        event.preventDefault();

        //this.props.onSubmit(this.state.query);
        history.push({
            pathname: "/search",
            search: "?q="+this.state.query+"&src=bar&by=products"
        });
    }
    onBackPressed = event => {
        event.preventDefault();
        history.goBack();
    }
    render() {
        return (
            <div className="ui fixed menu">
                {/* <div className="ui container" > */}
                    <div className="header borderless item">
                        <i className="material-icons" onClick ={this.onBackPressed}>arrow_back</i>
                    </div>
                    <div style={{width: '100%', margin: '8px'}}>
                        <form className="ui form" onSubmit={this.onFormSubmit}>
                            <div className="ui fluid left icon input">
                            <input className="search-input" type="search" placeholder="Cari di henata" aria-label="Search" value={this.state.query} onChange={e => this.setState({query: e.target.value})} />
                            <i className="search icon"></i>
                            </div>
                        </form>
                    </div>
                    
                {/* </div> */}
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {suggestion: state.searchKeywordSuggestion}
}
const connectSearchBar = connect(mapStateToProps, { 
    fetchSearchKeywordSuggestion, resetSearchKeywordSuggestion })(SearchBar);

export {connectSearchBar, SearchBarWithArrow};