import React from 'react';
import { connect } from 'react-redux';
import { fetchPengumuman } from '../actions/index';
import '../css/Home/grids.css'

import PengumumanItem from './PengumumanItem';

class PengumumanList extends React.Component {
    componentDidMount() {
        this.props.fetchPengumuman();
    }
    componentDidUpdate() {
        this.doRenderStyle();
    }
    doRenderStyle() {
        const lists = document.querySelectorAll('.hs'); 
        console.log("Lists", lists);
        lists.forEach(el => {
        //const listItems = el.querySelectorAll('li');
        const n = el.children.length;
        el.style.setProperty('--total', n);
        });
    }

    doRenderPengumuman() {
        if (this.props.listPengumuman.length === 1) {
            return <PengumumanItem item={this.props.listPengumuman[0]} active="true" />
        }
        else {
            const renderedPengumumanList = this.props.listPengumuman.map(item => {
                if (item.position_return === 1) {
                    return <PengumumanItem item={item} active="true" />
                }
                else {
                    return <PengumumanItem item={item} />
                }
            })
            return renderedPengumumanList;
        }
       
    }
    render() {
        return (
            // <div className="ui card fluid">
            //     <div className="image">
            //         <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            //             <div className="carousel-inner">
            //                 {this.doRenderPengumuman()}
            //             </div>
            //         </div>
            //     </div>
                
            // </div>
            <div className="nata-hs-container">
                <ul className="nata-hs-ul hs full">
                    {this.doRenderPengumuman()}
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    //state disini diambil dari reducers yang ada di ./reducers/index.
    //di index ada object dengan key 'listpengumuman', maka ini pun ada
    return { listPengumuman: state.listPengumuman }
}

export default connect(mapStateToProps, { fetchPengumuman })(PengumumanList);