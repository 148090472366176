import React from 'react';
import {Helmet} from 'react-helmet';
import {DESKTOP_SITE} from '../actions/types';

import PengumumanList from '../linimasa/PengumumanList';
import FeaturedKategoriList from '../kategori/FeaturedKategoriList';
import LinimasaList from '../linimasa/LinimasaList';
import { connectSEOHome as SEOHome } from '../universal/SEOHome';
import { connectSearchBar as  SearchBar} from '../universal/SearchBar';
import Footer from '../universal/Footer'

class HomePage extends React.Component {  
    doRenderHeader() {
        var titleStr = "Situs Tempat Belanja Kebutuhanmu! - Henata";
        var descriptionStr = "Henata, situs belanja kebutuhan yang mudah, hemat, terpercaya. Hemat waktu, banyak diskon, bisa cash on delivery."

        //console.log(this.props.location);
        return (
            <Helmet>
                <title>{titleStr}</title>
                <link rel="canonical" href={DESKTOP_SITE + this.props.location.pathname} />
                <meta name="description" content={descriptionStr} />
            </Helmet>
        );
    }
    render() {
        return (
            <div>
                {this.doRenderHeader()}
                <SearchBar />
                <div className="main container" style={{marginTop: "18%"}} >
                    <PengumumanList />
                    <FeaturedKategoriList />
                    <div class="ui grid">
                        <LinimasaList />
                    </div>
                    <SEOHome />
                </div>
                <Footer />
            </div>
        );
    }
}

export default HomePage;