import React from 'react';
import { fetchSEOHome } from '../actions/statics'
import { connect } from 'react-redux';

class SEOHome extends React.Component {
    loadFromApi() {
        this.props.fetchSEOHome();    
    }
    componentDidMount() {
        this.loadFromApi();
    }
    doRenderSEOHome() {
        var rendered;
        if (this.props.seoHome.isLoaded === true) {
            rendered = (
                <div className="seo-home" dangerouslySetInnerHTML={{__html: this.props.seoHome.data.statics_value_return }}></div>
            );
        }
        else {
            rendered = <div></div>;
        }
        return rendered;
    }
    render() {
        return (
            <div>
                {this.doRenderSEOHome()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        seoHome : state.seoHome
    }
}
const connectSEOHome = connect(mapStateToProps, {fetchSEOHome})(SEOHome);
export { connectSEOHome };