import React from 'react';
import history from '../history';
import { Router, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import SearchBar from './universal/SearchBar';
import HomePage from './pages/Home';
import { connectSearch as SearchPage } from './pages/Search';
import ProductDetailPage from './pages/ProductDetail';
import { connectPengumuman as PengumumanDetailPage } from './pages/Pengumuman';
import { connectLinimasa as LinimasaPage} from './pages/Linimasa';
import { 
    KategoriAll as KategoriAllPage, 
    connectKategoriDetail as KategoriDetailPage } from './pages/Kategori';
import { connectPrivacyPolicy as PrivacyPolicyPage, connectTermsCondition as TermsConditionPage } from './pages/Statics';
import './css/app.css';

class App extends React.Component {
    state = {
        searchResultArray: []
    }

    onSearchSubmit = async (query) => {
        // const response = await mapis.get('/search/query/barangdijual', {
        //     params: {
        //         q: query,
        //         jh: 1
        //     }
        // });

        //console.log(response);
    }

    render() {
        return (
            <div style={{display: "flex", flexDirection: "column", minHeight: "100vh"}}>
                <Router history={history}>
                    <div style={{flex: "1"}}>
                        <Route path={'/'} exact component={HomePage} />
                        <Route path={'/search'} exact component={SearchPage} />
                        <Route path={'/product/:slug'} exact component={ProductDetailPage} />
                        <Route path={'/kategori'} exact component={KategoriAllPage} />
                        <Route path={'/kategori/:kat_url_slug/:subkat_url_slug'} exact component={KategoriDetailPage} />
                        <Route path={'/kategori/:kat_url_slug'} exact component={KategoriDetailPage} />
                        <Route path={'/pengumuman/:id'} exact component={PengumumanDetailPage} />
                        <Route path={'/promo/:id'} exact component={LinimasaPage} />
                        {/* <Route path={'/kategori/:id'} exact component={KategoriDetailPage} /> */}
                        {/* <Route path={'/featured'} exact component={} /> */}
                        {/* <Route path={"/featured/:id"} exact component={} /> */}

                        <Route path={'/terms'} exact component={TermsConditionPage} />
                        <Route path={'/privacy'} exact component={PrivacyPolicyPage} />
                    </div>
                </Router>
            </div>
        );
    }
}

export default App;