import mapis from '../../api/mapis';
import { JENIS_REGULER } from './types';

export const fetchProductInfo = (slug) => async dispatch => {
    const jenis_pelanggan = JENIS_REGULER; //set default = 1 dulu.

    dispatch({
        type: 'FETCH_PRODUCT_INFO_RESET'
    })
    var url = `/mart/barang/dijual?url=${slug}&jenis=${jenis_pelanggan}`;
    const response = await mapis.get(url);

    if (response.data.status ==="success") {
        dispatch({
            type: 'FETCH_PRODUCT_INFO',
            payload: response.data.data[0]
        })
    }
}