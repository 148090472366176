import React from 'react';
import { Pagination as SemanticPagination } from 'semantic-ui-react';
import { DEFAULT_SIZE_PAGING } from '../actions/types'

class Pagination extends React.Component {
    handlePaginationChange = (e, {activePage}) => {
        if (!isNaN(activePage)) {
            this.props.onPaginationChange(activePage);
        }
    }
    doRenderPagination() {
        const { total, activeNow } = this.props;
        console.log("Page: ", total)
        if (total > 0 ) {
            var howManyPage = Math.ceil(total / DEFAULT_SIZE_PAGING);
            return <SemanticPagination boundaryRange="0" totalPages={howManyPage} activePage={activeNow} onPageChange={this.handlePaginationChange} />
        }
        else {

        }
    }
    render() {
        return (
            <div>
                {this.doRenderPagination()}
            </div>
        );
    }
}

export default Pagination;