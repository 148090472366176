const INITIAL_PENGUMUMAN_INFO = {
    isLoaded: false,
    isFound: false,
    data: null
}

export const listPengumuman = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_PENGUMUMAN':
            return action.payload;
        default:
            return state;
    }
}

export const pengumumanInfo = (state = INITIAL_PENGUMUMAN_INFO, action) => {
    switch (action.type) {
        case 'FETCH_PENGUMUMAN_BY_ID_RESET':
            return INITIAL_PENGUMUMAN_INFO;
        case 'FETCH_PENGUMUMAN_BY_ID':
            return {...state, isLoaded: true, isFound: true, data: action.payload};
        default:
            return state;
    }
}