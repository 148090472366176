const INITIAL_STATE_KATEGORI = {
    isLoaded: false,
    kategori: []
}
const INITIAL_STATE_KATEGORI_URL_SLUG = {
    isLoaded: false,
    isFound: false,
    url_slug: null,
    data: null
}
const INITIAL_STATE_SUBKATEGORI_URL_SLUG = {
    isLoaded: false,
    isFound: false,
    url_slug: null,
    data: null
}

export const listKategoriAll = (state = INITIAL_STATE_KATEGORI, action) => {
    switch(action.type) {
        case 'FETCH_KATEGORI_ALL_RESET':
            return INITIAL_STATE_KATEGORI;
        case 'FETCH_KATEGORI_ALL':
            return {...state, isLoaded: true, kategori: action.payload}
        default:
            return state;
    }
}

export const listSubKategoriByIdKategori = (state = [], action) => {
    switch(action.type) {
        case 'FETCH_SUB_KATEGORI_RESET':
            return [];
        case 'FETCH_SUB_KATEGORI':
            return [...state, action.payload];
        default:
            return state;
    }
}

export const kategoriByUrlSlug = (state = INITIAL_STATE_KATEGORI_URL_SLUG, action) => {
    switch(action.type) {
        case 'FETCH_KATEGORI_URL_SLUG_RESET':
            return INITIAL_STATE_KATEGORI_URL_SLUG;
        case 'FETCH_KATEGORI_URL_SLUG':
            return {...state, isLoaded: true, url_slug: action.url_slug, isFound: true, data: action.payload}
        case 'FETCH_KATEGORI_URL_SLUG_NOTFOUND':
            return {...state, isLoaded: true, url_slug: action.url_slug, isFound: false, data: null}
        default:
            return state;
    }
}

export const subkategoriByUrlSlug = (state = INITIAL_STATE_SUBKATEGORI_URL_SLUG, action) => {
    switch(action.type) {
        case 'FETCH_SUB_KATEGORI_URL_SLUG_RESET':
            return INITIAL_STATE_SUBKATEGORI_URL_SLUG;
        case 'FETCH_SUB_KATEGORI_URL_SLUG':
            return {...state, isLoaded: true, url_slug: action.url_slug, isFound: true, data: action.payload}
        case 'FETCH_SUB_KATEGORI_URL_SLUG_NOTFOUND':
            return {...state, isLoaded: true, url_slug: action.url_slug, isFound: false, data: null}
        default:
            return state;
    }
}