import mapis from '../../api/mapis';

export const fetchPrivacyPolicy = () => async dispatch => {
    dispatch({
        type: 'FETCH_PRIVACY_POLICY_RESET'
    });
    const response = await mapis.get('/mart/statics', {
        params: {
            key: 'KEBIJAKAN_PRIVASI'
        }
    });
    if (response.data.status==="success") {
        dispatch({
            type: 'FETCH_PRIVACY_POLICY',
            payload: response.data.data[0]
        })
    }
}

export const fetchTerms = () => async dispatch => {
    dispatch({
        type: 'FETCH_TERMS_RESET'
    });
    const response = await mapis.get('/mart/statics', {
        params: {
            key: 'SYARAT_KETENTUAN'
        }
    });
    if (response.data.status==="success") {
        dispatch({
            type: 'FETCH_TERMS',
            payload: response.data.data[0]
        })
    }
}

export const fetchSEOHome = () => async dispatch => {
    dispatch({
        type: 'FETCH_SEO_HOME_RESET'
    });
    const response = await mapis.get('/mart/statics', {
        params: {
            key: 'SEO_HOME'
        }
    });
    if (response.data.status==="success"){
        dispatch({
            type: 'FETCH_SEO_HOME',
            payload: response.data.data[0]
        })
    }
}