import React from 'react';
import images from '../../api/images';
import history from '../../history';
import { titleCase } from '../actions/types'
import '../css/home.css';
import '../css/products.css';

import NumberFormat from 'react-number-format';

class LinimasaItem extends React.Component {
    constructor(props) {
        super(props);
        //this.routeChange = this.routeChange.bind(this);
    }
    routeChange(slug) {
        //masih ngga tau caranya render
        let path = `/product/${slug}`;
        history.push(path);
    }
    isNotEmpty = (element) => {
        return element && element !== "" ? true: false;
    }
    showProductProperty() {
        var productProperty;
        var {item} = this.props;
        if (item.nama_satuan_barang_return && item.nama_satuan_barang_return !== "") {
            if (this.isNotEmpty(item.netto_return) && this.isNotEmpty(item.kode_measurement_return)) {
                if (item.jumlah_pcs_return > 1) {
                    productProperty = (
                        <div className="src_p_property_barang_text">
                            {`${item.nama_satuan_barang_return} (${item.jumlah_pcs_return} x ${parseInt(item.netto_return)}${item.kode_measurement_return})`}
                        </div>
                    )
                }
                else {
                    productProperty = (
                        <div className="src_p_property_barang_text">
                            {`${item.nama_satuan_barang_return} (${parseInt(item.netto_return)}${item.kode_measurement_return})`}
                        </div>
                    )
                }
            }
            else {
                productProperty = (
                    <div className="src_p_property_barang_text">
                        {`${item.nama_satuan_barang_return}`}
                    </div>
                )
            }
        }
        else {
            productProperty = <div></div>
        }
        return productProperty;
    }
    // showProductInfo() {
    //     if (this.props.diskon) {
    //         var priceText = <div></div>;
    //         var diskonValue = 0, diskonPersen = 0, hargaSetelahDiskon = 0;
    //         var objectDiskon = this.props.diskon;
    //         if (objectDiskon.min_pembelian_return === -1 && objectDiskon.max_pembelian_return === -1) {
    //             diskonValue = Number(objectDiskon.diskon_value_return);
    //             diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.item.harga_barang_return))/100;

    //             hargaSetelahDiskon = Number(this.props.item.harga_barang_return) - diskonValue - diskonPersen;
    //             priceText = (
    //                 <div>
    //                     <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
    //                     decimalSeparator={','} prefix={'Rp. '}
    //                     renderText={value => <div className="p_dsc_harga_barang_normal_text"><strike>{value}</strike></div>} />
    //                     <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
    //                     decimalSeparator={','} prefix={'Rp. '}
    //                     renderText={value => <div className="p_dsc_harga_barang_after_diskon_text">{value}</div>} />
    //                 </div>
    //             );
    //         }
    //         else if (objectDiskon.min_pembelian_return === -1 && objectDiskon.max_pembelian_return > 0) {
    //             diskonValue = Number(objectDiskon.diskon_value_return);
    //             diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.item.harga_barang_return))/100;

    //             hargaSetelahDiskon = Number(this.props.item.harga_barang_return) - diskonValue - diskonPersen;
    //             priceText = (
    //                 <div>
    //                     <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
    //                     decimalSeparator={','} prefix={'Rp. '}
    //                     renderText={value => <div className="p_harga_barang_text">{value}</div>}
    //                     />
    //                     <div className="p_diskon_condition_text">
    //                         Beli maks. {objectDiskon.max_pembelian_return} item,
    //                         <span style={{display: "inline-block"}}>
    //                             <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
    //                             decimalSeparator={','} prefix={'Rp. '}
    //                             renderText={value => <div>{value}</div>} />
    //                         </span>
    //                         / item.
    //                     </div>
    //                 </div>
    //             ); 
    //         }
    //         else if (objectDiskon.min_pembelian_return > 0 && objectDiskon.max_pembelian_return === -1) {
    //             diskonValue = Number(objectDiskon.diskon_value_return);
    //             diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.item.harga_barang_return))/100;

    //             hargaSetelahDiskon = Number(this.props.item.harga_barang_return) - diskonValue - diskonPersen;
                
    //             if (objectDiskon.min_pembelian_return > 1) {
    //                 priceText = (
    //                     <div>
    //                         <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
    //                         decimalSeparator={','} prefix={'Rp. '}
    //                         renderText={value => <div className="p_harga_barang_text">{value}</div>}
    //                         />
    //                         <div className="p_diskon_condition_text">
    //                             Beli min. {objectDiskon.min_pembelian_return} item,
    //                             <span style={{display: "inline-block"}}>
    //                                 <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
    //                                 decimalSeparator={','} prefix={'Rp. '}
    //                                 renderText={value => <div>{value}</div>} />
    //                             </span>
    //                             / item.
    //                         </div>
    //                     </div>
    //                 );
    //             }
    //             else {
    //                 priceText = (
    //                     <div>
    //                         <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
    //                         decimalSeparator={','} prefix={'Rp. '}
    //                         renderText={value => <div className="p_dsc_harga_barang_normal_text"><strike>{value}</strike></div>} />
    //                         <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
    //                         decimalSeparator={','} prefix={'Rp. '}
    //                         renderText={value => <div className="p_dsc_harga_barang_after_diskon_text">{value}</div>} />
    //                     </div>
    //                 );
    //             }
    //         }
    //         else if (objectDiskon.min_pembelian_return > 0 && objectDiskon.max_pembelian_return > 0) {
    //             diskonValue = Number(objectDiskon.diskon_value_return);
    //             diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.item.harga_barang_return))/100;

    //             hargaSetelahDiskon = Number(this.props.item.harga_barang_return) - diskonValue - diskonPersen;
    //             priceText = (
    //                 <div>
    //                     <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
    //                     decimalSeparator={','} prefix={'Rp. '}
    //                     renderText={value => <div className="p_harga_barang_text">{value}</div>}
    //                     />
    //                     <div className="p_diskon_condition_text">
    //                         Beli {objectDiskon.min_pembelian_return} - {objectDiskon.max_pembelian_return} item,
    //                         <span style={{display: "inline-block"}}>
    //                             <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
    //                             decimalSeparator={','} prefix={'Rp. '}
    //                             renderText={value => <div>{value}</div>} />
    //                         </span>
    //                         / item.
    //                     </div>
    //                 </div>
    //             );

    //         }
    //         return priceText;
    //     }
    //     else {
    //         return (
    //             <div>
    //                 <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
    //                 decimalSeparator={','} prefix={'Rp. '}
    //                 renderText={value => <div className="p_harga_barang_text">{value}</div>} />
                 
    //             </div>
                       
    //         );
    //     }
    // }
    showProductPrice() {
        if (this.props.diskon) {
            var priceText = <div></div>;
            var diskonValue = 0, diskonPersen = 0, hargaSetelahDiskon = 0;
            var objectDiskon = this.props.diskon;
            if (objectDiskon.min_pembelian_return === -1 && objectDiskon.max_pembelian_return === -1) {
                diskonValue = Number(objectDiskon.diskon_value_return);
                diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.item.harga_barang_return))/100;

                hargaSetelahDiskon = Number(this.props.item.harga_barang_return) - diskonValue - diskonPersen;
                priceText = (
                    <div>
                        <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className="src_p_dsc_harga_barang_normal_text"><strike>{value}</strike></div>} />
                        <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className="src_p_dsc_harga_barang_after_diskon_text">{value}</div>} />
                    </div>
                );
            }
            else if (objectDiskon.min_pembelian_return === -1 && objectDiskon.max_pembelian_return > 0) {
                diskonValue = Number(objectDiskon.diskon_value_return);
                diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.item.harga_barang_return))/100;

                hargaSetelahDiskon = Number(this.props.item.harga_barang_return) - diskonValue - diskonPersen;
                priceText = (
                    <div>
                        <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className="src_p_harga_barang_text">{value}</div>}
                        />
                        <div className="src_p_diskon_condition_text">
                            Beli maks. {objectDiskon.max_pembelian_return} item,
                            <span style={{display: "inline-block"}}>
                                <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'Rp'}
                                renderText={value => <div>{value}</div>} />
                            </span>
                            / item.
                        </div>
                    </div>
                ); 
            }
            else if (objectDiskon.min_pembelian_return > 0 && objectDiskon.max_pembelian_return === -1) {
                diskonValue = Number(objectDiskon.diskon_value_return);
                diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.item.harga_barang_return))/100;

                hargaSetelahDiskon = Number(this.props.item.harga_barang_return) - diskonValue - diskonPersen;
                
                if (objectDiskon.min_pembelian_return > 1) {
                    priceText = (
                        <div>
                            <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp'}
                            renderText={value => <div className="src_p_harga_barang_text">{value}</div>}
                            />
                            <div className="src_p_diskon_condition_text">
                                Beli min. {objectDiskon.min_pembelian_return} item,
                                <span style={{display: "inline-block"}}>
                                    <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                                    decimalSeparator={','} prefix={'Rp'}
                                    renderText={value => <div>{value}</div>} />
                                </span>
                                / item.
                            </div>
                        </div>
                    );
                }
                else {
                    priceText = (
                        <div>
                            <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp'}
                            renderText={value => <div className="src_p_dsc_harga_barang_normal_text"><strike>{value}</strike></div>} />
                            <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                            decimalSeparator={','} prefix={'Rp'}
                            renderText={value => <div className="src_p_dsc_harga_barang_after_diskon_text">{value}</div>} />
                        </div>
                    );
                }
            }
            else if (objectDiskon.min_pembelian_return > 0 && objectDiskon.max_pembelian_return > 0) {
                diskonValue = Number(objectDiskon.diskon_value_return);
                diskonPersen = (Number(objectDiskon.diskon_persen_return) * Number(this.props.item.harga_barang_return))/100;

                hargaSetelahDiskon = Number(this.props.item.harga_barang_return) - diskonValue - diskonPersen;
                priceText = (
                    <div>
                        <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                        decimalSeparator={','} prefix={'Rp'}
                        renderText={value => <div className="src_p_harga_barang_text">{value}</div>}
                        />
                        <div className="src_p_diskon_condition_text">
                            Beli {objectDiskon.min_pembelian_return} - {objectDiskon.max_pembelian_return} item,
                            <span style={{display: "inline-block"}}>
                                <NumberFormat value={hargaSetelahDiskon} displayType={'text'} thousandSeparator={'.'}
                                decimalSeparator={','} prefix={'Rp'}
                                renderText={value => <div>{value}</div>} />
                            </span>
                            / item.
                        </div>
                    </div>
                );

            }
            return priceText;
        }
        else {
            return (
                <div>
                    <NumberFormat value={this.props.item.harga_barang_return} displayType={'text'} thousandSeparator={'.'}
                    decimalSeparator={','} prefix={'Rp'}
                    renderText={value => <div className="src_p_harga_barang_text">{value}</div>} />
                 
                </div>
                       
            );
        }
    }
    render() {
        return (
            // <div className="card" style={{flex: "1", flexBasis:"0"}} onClick={() => this.routeChange(this.props.item.url_slug_return) }>
            <div className="nata-item-fl">
                {/* <div className="ui card" onClick={() => this.routeChange(this.props.item.url_slug_return) }>
                    <div class="image mini" style={{background: "white"}}>
                        <img src={images(this.props.item.main_img_url_return)} style={{
                            height: '150px', width: 'auto', maxWidth: '100%', objectFit: 'fill',
                            marginLeft:'auto', marginRight: 'auto', display: 'block'
                            }} />
                    </div>    
                    <div className="content">
                        <div className="p_nama_barang_text">
                        {titleCase(this.props.item.nama_barang_return)}
                        </div>
                        <div style={{margin: '3px'}}>
                            {this.showProductInfo()}
                        </div>
                    </div>
                </div> */}
                <div className="column" style={{cursor: "pointer"}} onClick={() => this.routeChange(this.props.item.url_slug_return) }>
                    <div className="card border shadow-item">
                        <div class="image mini" style={{height: "140px", width: "140px", display: "flex"}}>
                            <img alt={this.props.item.nama_barang_return} src={images(this.props.item.main_img_url_return)} style={{
                                height: '125px', width: 'auto', maxWidth: '100%', objectFit: "fill", flex: "0 1 auto", alignSelf: "center",
                                marginLeft:'auto', marginRight: 'auto', display: 'block'
                                }} />
                        </div>
                    </div>
                    
                    <div>
                        <div className="src_p_nama_barang_text">
                        {this.props.item.nama_barang_return}
                        </div>
                        {this.showProductProperty()}
                        <div style={{marginTop: '10px'}}>
                            {this.showProductPrice()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LinimasaItem;